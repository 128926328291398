import { Routes, Route, useLocation } from "react-router-dom";

import "./App.scss";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import MaterialContextProvider from "./context/MaterialContext";
import UserContextProvider from "./context/UserContext";
import ProtectedRoutes from "./utilities/ProtectedRoutes";
import VendorRoutes from "./utilities/VendorRoutes";
import Footer from "./components/Footer";

import Landing from "./pages/Landing";
import LoginForm from "./components/LoginForm";
import SpecialLogin from "./components/SpecialLogin";
import Library from "./pages/Library";
import Collection from "./pages/Collection";
import Contact from "./pages/Contact";
import SingleMaterial from "./pages/SingleMaterial";

import MaterialAdd from "./pages/vendor/MaterialAdd";
import MaterialEdit from "./pages/vendor/MaterialEdit";
import MaterialPrice from "./pages/vendor/MaterialPrice";
import UnprotectedRoutes from "./utilities/UnprotectedRoutes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

function App() {
  const location = useLocation();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <UserContextProvider>
          <MaterialContextProvider>
            <Routes>
              {/* EXPOSED ROUTES */}
              <Route element={<UnprotectedRoutes />}>
                <Route path="/contact" element={<Contact />}></Route>
              </Route>

              <Route path="/" element={<Landing />}>
                <Route
                  path={"/"}
                  element={<LoginForm brand={location.pathname} />}
                />
              </Route>

              {/* PROTECTED ROUTES */}
              <Route element={<ProtectedRoutes />}>
                {/* DESIGNER AND GUEST VIEWABLE */}
                <Route path="/library" element={<Library />} />
                <Route
                  path="/loading"
                  element={<div>Getting your PDF ready</div>}
                />
                <Route path="/collection" element={<Collection />} />
                <Route path="/:vendor/:slug" element={<SingleMaterial />} />
                {/* VENDOR ONLY */}
                <Route element={<VendorRoutes />}>
                  <Route path="/material/price" element={<MaterialPrice />} />
                  <Route path="/material/add" element={<MaterialAdd />} />
                  <Route
                    path="material/edit/:vendor/:slug"
                    element={<MaterialEdit />}
                  />
                </Route>
              </Route>
              <Route
                path={"/*"}
                element={<SpecialLogin path={location.pathname} />}
              />
            </Routes>

            <Footer />
          </MaterialContextProvider>
        </UserContextProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
