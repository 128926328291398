import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import fuzzysort from "fuzzysort";
import { MaterialContext } from "../../context/MaterialContext";
import { UserContext } from "../../context/UserContext";

import inputStyle from "../../styles/input.module.scss";
import { closeIcon } from "../../components/Icons";

function MaterialAdd() {
  const { allMaterials } = useContext(MaterialContext);
  const { userInformation } = useContext(UserContext);
  const [fuzzyResults, setFuzzyResults] = useState([]);
  const [input, setInput] = useState("");

  const { vendor, userType } = userInformation;

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const optionsFuzzy = {
    limit: 10, // don't return more results than you need!
    allowTypo: false, // if you don't care about allowing typos
    threshold: -100, // don't return bad results
    keys: ["title"],
  };

  useEffect(() => {
    const filteredMaterials = allMaterials
      .filter((item) => item.vendor === vendor)
      .map((items) => items);
    const resultsFuzzy = fuzzysort.go(input, filteredMaterials, optionsFuzzy);
    setFuzzyResults(resultsFuzzy);
    // eslint-disable-next-line
  }, [input, allMaterials, vendor]);

  const handleClear = () => {
    setInput("");
  };

  return (
    <div className="content">
      <div className={inputStyle.inputBar}>
        <div className={inputStyle.vendorName}>
          {userType} from: {vendor}
        </div>
        <div className={inputStyle.search}>
          <input
            type="text"
            placeholder="input"
            value={input}
            onChange={handleInput}
          />
          {input && (
            <span className={inputStyle.clearSearch} onClick={handleClear}>
              {closeIcon}
            </span>
          )}
        </div>
        {input.length >= 3 && (
          <>
            <div className={inputStyle.actionBar}>
              <Link
                to={`/material/edit/${vendor}/${input
                  .toLowerCase()
                  .replace(/\s/g, "-")}`}
              >
                Create {vendor} {input}
              </Link>
            </div>
          </>
        )}
        <div className={inputStyle.itemsArray}>
          {fuzzyResults?.map((item) => (
            <li className={inputStyle.itemsArrayList} key={item.obj.id}>
              <Link
                to={`/material/edit/${item.obj.vendor}/${item.obj.slug
                  .toLowerCase()
                  .replace(/\s/g, "-")}`}
              >
                Edit {item.obj.vendor} {item.obj.title}
              </Link>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MaterialAdd;
