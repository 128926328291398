import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { useNavigate, useParams } from "react-router-dom";

function SpecialLogin({ path }) {
  const { changeUserInformation, userInformation } = useContext(UserContext);
  const [time, setTime] = useState(5);
  const guestPath = path.substring(1);
  const params = useParams();
  const navigate = useNavigate();

  const pathOptions = ["nike", "lib", "vans"];
  const quickPath = params["*"];

  //const searchParams = useSearchParams();

  const { isLoggedIn } = userInformation;

  useEffect(() => {
    if (!isLoggedIn && pathOptions.includes(quickPath)) {
      handleSpecialUserLogin();
    } else {
      console.log("not logged in yet");
    }
    // eslint-disable-next-line
  }, [isLoggedIn, pathOptions, quickPath]);

  const handleSpecialUserLogin = () => {
    if (!isLoggedIn) {
      changeUserInformation({
        userType: "guest",
        vendor: quickPath,
        userName: quickPath + "guest",
        isLoggedIn: true,
      });
    }
    navigate("/library");
  };

  useEffect(() => {
    if (time !== 0) {
      setInterval(() => {
        setTime(time - 1);
      }, 1000);
    } else {
      setTime(0);
    }
  }, [time]);

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     console.log("user not Logged in");
  //     setTimeout(() => {
  //       console.log("something happened on handleSpecialUserLogin");
  //       //handleSpecialUserLogin();
  //     }, 3000);
  //   } else {
  //     console.log("user Logged in");
  //     //handleSpecialUserLogin();
  //   }
  // }, [guestPath, isLoggedIn]);

  if (pathOptions.includes(guestPath)) {
    return (
      <div className="content-full">
        <div className="login-view">
          <div className="login-view-content">
            <h2 className="login-view-heading">
              {/* Special Login for {guestPath} */}
            </h2>
            {/* <div onClick={handleSpecialUserLogin}>Login {time}</div> */}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="content-full">
      <div className="login-view">
        <div className="login-view-content">
          <h2 className="login-view-heading">
            {/* Special Login for {guestPath} */}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default SpecialLogin;
