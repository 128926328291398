import { useState, createContext } from "react";
import { useNavigate } from "react-router-dom";
import MediaQuery from "../utilities/MediaQuery";

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const user = JSON.parse(localStorage.getItem("user")) || "";
  const token = JSON.parse(localStorage.getItem("token")) || "";
  const localCollection = JSON.parse(localStorage.getItem("collectionId"));

  const navigate = useNavigate();

  const [userInformation, setUserInformation] = useState({
    userType: user.userType || "",
    vendor: user.vendor || "",
    userName: user.userName || "",
    isLoggedIn: token ? true : false,
  });

  const [collection, setCollection] = useState(
    localCollection ? localCollection : []
  );

  const handleLogOutUser = () => {
    const logoutInfo = {
      userType: "",
      vendor: "",
      userName: "",
      isLoggedIn: false,
    };
    setUserInformation(logoutInfo);
    localStorage.setItem("user", JSON.stringify(logoutInfo));
    localStorage.removeItem("token");
  };

  const media = MediaQuery();

  const value = {
    media,
    userInformation,
    changeUserInformation: (value) => {
      setUserInformation(value);
      localStorage.setItem("user", JSON.stringify(value));
      navigate("/library");
    },
    changelogOutUser: () => {
      handleLogOutUser();
    },
    collection,
    changeCollection: (value) => {
      if (collection.includes(value)) {
        setCollection(collection.filter((like) => like !== value));
        localStorage.setItem(
          "collectionId",
          JSON.stringify(collection.filter((like) => like !== value))
        );
      } else {
        setCollection([...collection, value]);
        localStorage.setItem(
          "collectionId",
          JSON.stringify([...collection, value])
        );
      }
    },
    updateCollection: (value) => {
      localStorage.setItem("collectionId", JSON.stringify(value));
    },
  };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};

export default UserContextProvider;
