import { useState, useEffect, useContext } from "react";
import LazyLoad from "react-lazy-load";
import CollectionButton from "./CollectionButton";
import ToolTip from "./ToolTip";
import { MaterialContext } from "../context/MaterialContext";
import { UserContext } from "../context/UserContext";
import { nikeLogo } from "./Icons";

import swatchStyle from "../styles/swatch.module.scss";

import MaterialModal from "./MaterialModal";

function Swatch({ id, uploadSlug, uploadVendor }) {
  const {
    allMaterials,
    nikeData,
    uploadImages,
    search,
    changeSearch,
    ROOT_SERVER,
  } = useContext(MaterialContext);
  const { userInformation } = useContext(UserContext);

  const [materialData, setMaterialData] = useState([]);
  const [viewing, setViewing] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageNumber, setImageNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isNike, setIsNike] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const [showToolTip, setShowToolTip] = useState(false);

  function showLogo() {
    if (
      userInformation.vendor === "asiatan" ||
      userInformation.vendor === "nike"
    ) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (id) {
      const getMaterial = allMaterials.find((material) => material.id === id);
      setMaterialData(getMaterial);
      const getNikeData = nikeData.filter(
        (item) => item?.slug.indexOf(getMaterial?.slug) !== -1
      );
      if (getNikeData.length > 0) {
        setIsNike(true);
        setBrandData(getNikeData);
      } else {
        setIsNike(false);
      }
      setViewing(
        `${ROOT_SERVER}${getMaterial?.vendor}/${getMaterial?.slug}/swatch/${getMaterial?.images[imageNumber]}`
      );
    } else {
      const imageArr = uploadImages.map((item) => item.preview);
      setMaterialData({
        title: uploadSlug,
        images: imageArr,
        vendor: uploadVendor,
        description: "waiting",
      });
      setViewing(uploadImages[imageNumber].preview);
    }
    // eslint-disable-next-line
  }, [allMaterials, imageNumber, id, nikeData, search]);

  const handleChangeImage = (i) => {
    setImageLoaded(false);
    setImageNumber(i);
  };
  const handleImageLoaded = () => {
    setImageLoaded(true);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };

  if (materialData) {
    const { title, description, vendor, slug, images } = materialData;

    return (
      <>
        {showModal && (
          <MaterialModal
            id={id}
            showModal={showModal}
            setShowModal={setShowModal}
            brandData={brandData}
            userInformation={userInformation}
          />
        )}
        <div className={swatchStyle.swatch} data-id={id}>
          <LazyLoad offset={200} threshold={1}>
            <img
              onClick={handleShowModal}
              className={
                imageLoaded
                  ? `${swatchStyle.imageBlur} ${swatchStyle.loaded}`
                  : `${swatchStyle.imageBlur}`
              }
              src={viewing}
              alt={slug}
              onLoad={handleImageLoaded}
            />
          </LazyLoad>
          <div className={swatchStyle.vendor}>
            <p
              className={swatchStyle.vendorLabel}
              onClick={() => changeSearch(vendor)}
            >
              {vendor}
            </p>
            {isNike && showLogo() ? (
              <div
                className={swatchStyle.logo}
                onMouseOver={() => setShowToolTip(true)}
                onMouseLeave={() => setShowToolTip(false)}
              >
                {nikeLogo}
                <ToolTip
                  brandData={brandData
                    .filter((item) => item.slug === slug)
                    .map((item) => item)}
                  setShowToolTip={setShowToolTip}
                  showToolTip={showToolTip}
                />
              </div>
            ) : (
              false
            )}
          </div>
          <div className={swatchStyle.collectionButton}>
            <CollectionButton materialId={id} />
          </div>

          {images?.length >= 2 && (
            <div className={swatchStyle.imageSelector}>
              {images?.map((image, i) => (
                <span
                  key={image}
                  onClick={() => handleChangeImage(i)}
                  style={
                    imageNumber === i
                      ? { background: "transparent" }
                      : { background: "#FEFEFE90" }
                  }
                />
              ))}
            </div>
          )}

          <div className={swatchStyle.information}>
            {!imageLoaded && <div className={swatchStyle.loadingBar} />}
            <h3>{title}</h3>
            <h4>{description}</h4>
          </div>
        </div>
      </>
    );
  } else {
    <div className={swatchStyle.swatch} data-id={id}>
      Loading
    </div>;
  }
}

export default Swatch;
