import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import navStyle from "../styles/nav.module.scss";
import { vendorIcon, designerIcon, guestIcon } from "./Icons";
import UserMenuOptions from "../utilities/UserMenuOptions";

function UserBox({
  userInformation,
  userMenuIsVisible,
  setUserMenuIsVisible,
  changelogOutUser,
}) {
  const [menu, setMenu] = useState([]);
  const [viewing, setViewing] = useState(false);

  useEffect(() => {
    setMenu(UserMenuOptions(userInformation.userType));
  }, [userInformation.userType]);
  const userType = (type) => {
    if (type === "vendor") {
      return <div className={navStyle.userRole}> {vendorIcon} ENDOR VIEW </div>;
    }
    if (type === "designer") {
      return (
        <div className={navStyle.userRole}> {designerIcon} ESIGNER VIEW</div>
      );
    }
    if (type === "guest") {
      return <div className={navStyle.userRole}> {guestIcon} UEST</div>;
    }
  };

  useEffect(() => {
    const int = setTimeout(() => {
      if (!viewing) {
        setUserMenuIsVisible(false);
      }
    }, 5000);
    return () => {
      clearInterval(int);
    };
    // eslint-disable-next-line
  }, [viewing]);

  const handleLogOutUser = () => {
    changelogOutUser();
  };

  if (!userInformation.isLoggedIn) {
    return <div>Nothing here</div>;
  }
  return (
    <>
      <div
        className={
          userMenuIsVisible
            ? `${navStyle.userBox}`
            : `${navStyle.userBox} ${navStyle.hidden}`
        }
        onMouseLeave={() => setViewing(false)}
        onMouseEnter={() => setViewing(true)}
      >
        <div className={navStyle.userBoxContent}>
          {userType(userInformation.userType)}
          {menu.map((item) => (
            <Link
              key={item.id}
              to={item.buttonAction}
              className={navStyle.menuButton}
              onClick={() => setUserMenuIsVisible(false)}
            >
              {item.buttonName}
            </Link>
          ))}
          <div className={navStyle.userBoxName} onClick={handleLogOutUser}>
            <h4>
              {userInformation.userName} @ {userInformation.vendor}
            </h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserBox;
