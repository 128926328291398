import { useState, useContext, useEffect } from "react";
import landingStyle from "../styles/landing.module.scss";
import LoginFunction from "../utilities/LoginFunction";
import { UserContext } from "../context/UserContext";
import useToken from "../utilities/hooks/useToken";
import { useNavigate } from "react-router-dom";

function LoginForm() {
  const { changeUserInformation, userInformation } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remotePassword, setRemotePassword] = useState("");
  const [userInfo, setUserInfo] = useState([]);
  const { token, setToken } = useToken();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // const testthing = "jsmith@asiatan.com";
  // const name = testthing.split("@")[0];
  // const brand = testthing.slice(testthing.indexOf("@") + 1).split(".")[0];

  // console.log(name, brand);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const login = LoginFunction(email);
    login.then((response) => {
      if (response.id) {
        setUserInfo(response);
        setToken(response.accessToken);
        setRemotePassword(response.password);
        setMessage("");
        setLoading(false);
      } else {
        setMessage("email not found");
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (
      userInfo.userType === "vendor" &&
      token?.length > 20 &&
      userInfo.password !== ""
    ) {
      if (password === remotePassword) {
        changeUserInformation({
          userType: userInfo.userType,
          vendor: userInfo.brand,
          userName: userInfo.userName,
          isLoggedIn: true,
        });
        console.log("Vendor with password");
      } else {
        setMessage("");
        if (password.length && password !== remotePassword) {
          setMessage("password incorrect");
        }
      }
    }
    if (
      userInfo.userType === "vendor" &&
      token?.length > 20 &&
      userInfo.password === ""
    ) {
      if (password === remotePassword) {
        changeUserInformation({
          userType: userInfo.userType,
          vendor: userInfo.brand,
          userName: userInfo.userName,
          isLoggedIn: true,
        });
        console.log("Vendor no password");
      }
    }
    if (
      userInfo.userType === "designer" &&
      token?.length > 20 &&
      userInfo.password === ""
    ) {
      changeUserInformation({
        userType: userInfo.userType,
        vendor: userInfo.brand,
        userName: userInfo.userName,
        isLoggedIn: true,
      });
      console.log("designer no password");
    }
    // eslint-disable-next-line
  }, [userInfo]);

  const inputField = (e) => {
    setEmail(e.target.value);
    setMessage("");
  };
  const passwordField = (e) => {
    setPassword(e.target.value);
    setMessage("");
  };

  const { isLoggedIn, userName } = userInformation;

  if (userName && isLoggedIn) {
    return (
      <div className={landingStyle.loginView}>
        <div className={landingStyle.loginViewContent}>
          <h2 className={landingStyle.loginViewHeading}>
            you're logged in as {userInformation.userName} from{" "}
            {userInformation.vendor}
          </h2>
          <div onClick={() => navigate("/library")}>go to Library</div>
        </div>
      </div>
    );
  }

  return (
    <div className={landingStyle.loginView}>
      <form className={landingStyle.loginForm}>
        <div className={landingStyle.loginInputView}>
          <input type="email" placeholder="enter email" onChange={inputField} />
          {userInfo.userType === "vendor" ? (
            <input
              type="password"
              placeholder="password"
              onChange={passwordField}
            />
          ) : null}
          {message !== "" ? (
            <div className={landingStyle.loginErrorMessage}>{message}</div>
          ) : null}
        </div>
        {loading ? <div className="spinner"></div> : false}
        <button
          className={landingStyle.button}
          type="submit"
          onClick={handleSubmit}
        >
          LOGIN
        </button>
      </form>
    </div>
  );
}

export default LoginForm;
