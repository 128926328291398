import { useContext, useState, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { MaterialContext } from "../context/MaterialContext";
import Swatch from "../components/Swatch";
import { starOutlineIcon } from "../components/Icons";
import { storedColl } from "../utilities/StoredCollectionTest";

function Collection() {
  const { allMaterials } = useContext(MaterialContext);
  const { collection, updateCollection } = useContext(UserContext);
  const [checkedCollection, setCheckedCollection] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [storedCollection, setStoredCollection] = useState(false);
  const [storedId, setStoredId] = useState("");

  useEffect(() => {
    if (!storedCollection) {
      const isExist = allMaterials
        .filter(({ id }) => collection.includes(id))
        .map((item) => item.id);
      if (isExist.length === collection.length) {
        setCheckedCollection(isExist);
        setLoaded(true);
      } else {
        if (collection.length >= 1 && collection.length !== isExist.length) {
          const notExist = collection
            .filter((item) => isExist.includes(item))
            .map((item) => item);
          setCheckedCollection(notExist);
          setLoaded(true);
          updateCollection(notExist);
        }
      }
    }
  }, [collection, allMaterials, updateCollection, storedCollection]);

  const handleStoredCollection = (collection, id) => {
    setStoredCollection(true);
    setCheckedCollection(collection);
    setStoredId(id);
  };

  const handleClearStoredCollection = () => {
    setStoredCollection(false);
    setCheckedCollection(collection);
    setStoredId("");
  };

  const stored = (
    <div className="stored-collection-box">
      <div className="stored-collection-area">
        {storedCollection ? (
          <button className="button" onClick={handleClearStoredCollection}>
            View your collection
          </button>
        ) : (
          false
        )}
        <h5>View other collections:</h5>
        <div className="stored-collection-button-group">
          {storedColl
            .filter((item) => storedId !== item.id)
            .map((item) => (
              <div
                key={item.id}
                onClick={() => handleStoredCollection(item.collection, item.id)}
                className="button"
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundImage: `url(http://node.matlib.net/images/${item.image}`,
                }}
              >
                <h5>{item.title}</h5>
                <h6>{item.collection.length} swatches</h6>
                {storedId === item.id ? <h6>Viewing</h6> : false}
              </div>
            ))}
        </div>
      </div>
    </div>
  );

  if (!storedCollection && collection.length >= 1) {
    return (
      <div className="content">
        <div className="swatch-view">
          {checkedCollection.map((id) => (
            <Swatch key={id} id={id} />
          ))}
          {stored}
        </div>
      </div>
    );
  }

  if (loaded) {
    return (
      <div className="content">
        <div className="stored-header">
          <h3 style={{ color: "#FEFEFE90", marginBottom: "10px" }}>
            STORED COLLECTION: {storedId}{" "}
          </h3>
          <button
            className="basic-button"
            onClick={handleClearStoredCollection}
          >
            clear
          </button>
        </div>

        <div className="swatch-view">
          {checkedCollection?.length > 0 ? (
            <>
              {checkedCollection.map((id) => (
                <Swatch key={id} id={id} />
              ))}
              {stored}
            </>
          ) : (
            <div className="empty-collection">
              <div className="empty-collection-message">
                <div className="text-box">
                  <h2>You don't have and selected any materials yet.</h2>
                  <h4>
                    Click on the {starOutlineIcon} to save selected swatches to
                    your collection
                  </h4>
                </div>
                <div className="stored-collection-area">
                  <h5>View other collections:</h5>
                  <div className="stored-collection-button-group">
                    {storedColl.map((item) => (
                      <div
                        key={item.id}
                        onClick={() =>
                          handleStoredCollection(item.collection, item.id)
                        }
                        className="button"
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundImage: `url(http://localhost:5001/images/${item.image}`,
                        }}
                      >
                        <h5>{item.title}</h5>
                        <h6>{item.collection.length} swatches</h6>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="content">
        <h2>Loading collection...</h2>
      </div>
    );
  }
}

export default Collection;
