import { useNavigate } from "react-router-dom";
function Footer() {
  const navigate = useNavigate();
  return (
    <div className="footer-bar">
      <div className="footer-copy">MATLIB 2023</div>
      <div className="footer-contact" onClick={() => navigate("/contact")}>
        CONTACT
      </div>
    </div>
  );
}

export default Footer;
