export const storedColl = [
  {
    id: 1,
    title: "TecTuff Range",
    collection: [1648, 1746, 1740, 1741],
    image: "defaultThumb.jpg",
  },
  {
    id: 2,
    title: "selected swatches",
    collection: [1615, 1770, 1651],
    image: "selectedThumb.jpg",
  },
  {
    id: 3,
    title: "Asiatan Perforations",
    collection: [
      1702, 1789, 1659, 1693, 1677, 1780, 1679, 1700, 1697, 1685, 1686, 1698,
      1684, 1599, 1601,
    ],
    image: "tecTuffThumb.jpg",
  },
];
