import { Outlet } from "react-router-dom";
import landingStyle from "../styles/landing.module.scss";

function Landing() {
  return (
    <div className="content-full">
      <div className={landingStyle.loginView}>
        <div className={landingStyle.loginViewContent}>
          <h1>MATLIB</h1>
          <h4>Digital Materials Library</h4>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
